<script setup lang="ts">
  import SiteSearch from '@/views/productCard/components/SiteSearch.vue';
  import BreadCrumb from '@/components/ui/nav/breadcrumb/BreadCrumb.vue';
  import { useRoute } from 'vue-router';
  import { ref, watch } from 'vue';
  import type { IBreadcrumb } from '@/types/breadcrumbs';
  import { useBreadCrumbStore } from '@/stores/breadCrumbStore';
  import { storeToRefs } from 'pinia';

  const breadCrumbs = ref<IBreadcrumb[] | null>(null);

  const isSearch = ref<boolean>(false);

  const route = useRoute();

  const breadCrumbStore = useBreadCrumbStore();

  const { dynamicRoute } = storeToRefs(breadCrumbStore);

  const initNavSection = () => {
    const breadCrumbsMeta = route.meta?.breadCrumbs;
    const isSearchMeta = route.meta?.search;
    const dynamicRoutes = ['OfferView', 'NewsPage'];

    isSearchMeta ? (isSearch.value = isSearchMeta) : (isSearch.value = false);

    if (breadCrumbsMeta) {
      dynamicRoutes.forEach((crumb) => {
        if (route.name === crumb) {
          let newBreadCrumbs = [...breadCrumbsMeta];
          newBreadCrumbs[newBreadCrumbs.length - 1].name = dynamicRoute;
          return (breadCrumbs.value = newBreadCrumbs);
        }
      });
      breadCrumbs.value = breadCrumbsMeta;
    }

    if (!breadCrumbsMeta) {
      return (breadCrumbs.value = null);
    }
  };

  watch(
    () => route.meta,
    () => initNavSection()
  );

  watch(
    () => dynamicRoute.value,
    () => initNavSection()
  );
</script>

<template>
  <v-container v-if="breadCrumbs">
    <v-row :class="isSearch ? 'align-items-center' : 'align-items-end'">
      <BreadCrumb :breadcrumbs="breadCrumbs" />
      <SiteSearch v-if="isSearch" />
    </v-row>
  </v-container>
</template>

<style lang="scss" scoped>
  .row {
    max-height: 72px;
  }

  .col-md-5 {
    max-height: 72px;
  }
</style>
