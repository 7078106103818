import { useAuthStore } from '@/stores/authStore';
import { useModalStore } from '@/stores/modalStore';
import { useQuery } from '@tanstack/vue-query';
import { UserService } from '@/services/user.service';
import { useError } from '@/hooks/useError';
import type { resetTokenForm } from '@/services/types/dataTypes';
import type { Ref } from 'vue';

export const useResetPassword = (formData) => {
  const modalStore = useModalStore();

  const { toggleEnterCodeIsOpen, toggleSuccessIsOpen, toggleResetIsOpen } =
    modalStore;

  const { isInitialLoading: isLoading, refetch } = useQuery({
    queryKey: ['resetPassword'],
    queryFn: () => UserService.resetPassword(formData.value),
    enabled: false,
    onSuccess: ({ data }) => {
      if (data.success === 'success' && data.smsErrorCode) {
        toggleResetIsOpen();
        return toggleSuccessIsOpen();
      }

      if (data.smsErrorCode === '0') {
        toggleResetIsOpen();
        return toggleEnterCodeIsOpen();
      }
    },
  });
  return { isLoading, refetch };
};
export const useResetPasswordByToken = (
  resetTokenForm: Ref<resetTokenForm>
) => {
  const authStore = useAuthStore();

  const modalStore = useModalStore();

  const { setUserToken } = authStore;

  const { toggleEnterCodeIsOpen, togglePickPassword } = modalStore;

  const { isInitialLoading: isLoadingReset, refetch: refetchReset } = useQuery({
    queryKey: ['resetPasswordByToken'],
    queryFn: () => UserService.resetPasswordByToken(resetTokenForm.value),
    enabled: false,
    onSuccess: ({ data }) => {
      toggleEnterCodeIsOpen();
      setTimeout(() => setUserToken(data.token.plainTextToken), 150);
      setTimeout(() => togglePickPassword(), 300);
    },
    onError: (error) => useError(error),
  });

  return { isLoadingReset, refetchReset };
};
