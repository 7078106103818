<script setup lang="ts">
  import { useAuthStore } from '@/stores/authStore';
  import { storeToRefs } from 'pinia';
  import { onMounted } from 'vue';
  import { useModalStore } from '@/stores/modalStore';
  import { useRoute } from 'vue-router';
  import MyHeader from '@/components/ui/header/MyHeader.vue';
  import MyFooter from '@/components/footer/MyFooter.vue';
  import NavSection from '@/components/ui/nav/NavSection.vue';
  import MySideBar from '@/components/MySideBar/MySideBar.vue';
  import ResetModal from '@/components/modals/resetModal/ResetModal.vue';
  import EnterCodeModal from '@/components/modals/enterCodeModal/EnterCodeModal.vue';
  import PickPasswordModal from '@/components/modals/pickPasswordModal/PickPasswordModal.vue';
  import SuccessModal from '@/components/modals/successModal/SuccessModal.vue';
  import MyAlert from '@/components/ui/alerts/MyAlert.vue';

  const store = useAuthStore();
  const modalStore = useModalStore();

  const { isLoggedIn } = storeToRefs(store);

  const {
    resetIsOpen,
    enterCodeIsOpen,
    pickPasswordIsOpen,
    successMailIsOpen,
  } = storeToRefs(modalStore);

  const {
    toggleResetIsOpen,
    toggleAuthIsOpen,
    toggleEnterCodeIsOpen,
    toggleRegIsOpen,
    togglePickPassword,
    toggleSuccessIsOpen,
  } = modalStore;

  const route = useRoute();

  onMounted(async () => {
    if (route.query.email || route.query.phone) {
      return toggleEnterCodeIsOpen();
    }
  });
</script>

<template>
  <v-app class="position-relative" theme="default">
    <MyHeader :is-logged-in="isLoggedIn" />
    <MySideBar />
    <MyAlert />
    <v-main>
      <NavSection />
      <router-view v-slot="{ Component }">
        <v-slide-x-reverse-transition leave-absolute hide-on-leave>
          <component :is="Component" />
        </v-slide-x-reverse-transition>
      </router-view>
    </v-main>
    <MyFooter />
    <ResetModal
      :open="resetIsOpen"
      @closeModal="toggleResetIsOpen"
      @prevStep="toggleAuthIsOpen"
    />
    <EnterCodeModal
      :open="enterCodeIsOpen"
      @closeModal="toggleEnterCodeIsOpen"
      @prevStep="toggleRegIsOpen"
    />
    <PickPasswordModal
      :open="pickPasswordIsOpen"
      @closeModal="togglePickPassword"
    />

    <SuccessModal :open="successMailIsOpen" @closeModal="toggleSuccessIsOpen" />
  </v-app>
</template>

<style lang="scss">
  .v-container {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;
    @media (min-width: 1200px) {
      max-width: 1153px;
    }
  }

  .v-main {
    padding-top: 142px !important;
    padding-left: 0 !important;
    color: #454443 !important;

    @media (max-width: 768px) {
      padding-top: 100px !important;
    }
  }
</style>
