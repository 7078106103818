<script setup lang="ts">
  import { useLogout } from '@/hooks/queries/user/useLogout';
  const { refetch } = useLogout();
</script>

<template>
  <v-hover v-slot="{ isHovering, props }">
    <v-sheet class="position-relative" v-bind="props">
      <button
        class="btn-icon btn-icon-secondary btn-icon-lg user text-wrap is-active"
        type="button"
      >
        <span class="text">Личный кабинет</span>
      </button>
      <v-slide-y-transition>
        <v-sheet v-if="isHovering" class="position-absolute">
          <v-list
            class="drop-list text-center"
            width="180"
            rounded="3"
            elevation="3"
            border="#DED8D1"
          >
            <v-list-item to="/account" title="Личный кабинет" />
            <v-list-item to="/user/edit" title="Настройки" />
            <v-list-item title="Выйти из профиля" @click.prevent="refetch" />
          </v-list>
        </v-sheet>
      </v-slide-y-transition>
    </v-sheet>
  </v-hover>
</template>

<style scoped>
  .drop-list {
    position: absolute;
    left: 42%;
    transform: translateX(-32%);
  }
</style>
