<template>
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5303 6.53033C12.8232 6.23744 12.8232 5.76256 12.5303 5.46967L7.75736 0.6967C7.46447 0.403806 6.98959 0.403806 6.6967 0.6967C6.40381 0.989593 6.40381 1.46447 6.6967 1.75736L10.9393 6L6.6967 10.2426C6.40381 10.5355 6.40381 11.0104 6.6967 11.3033C6.98959 11.5962 7.46447 11.5962 7.75736 11.3033L12.5303 6.53033ZM-6.55671e-08 6.75L12 6.75L12 5.25L6.55671e-08 5.25L-6.55671e-08 6.75Z"
      fill="currentcolor"
    />
  </svg>
</template>
