import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { UserService } from '@/services/user.service';
import { useError } from '@/hooks/useError';
import type { registerPasswordForm } from '@/services/types/dataTypes';
import { useModalStore } from '@/stores/modalStore';

export const useRegisterPassword = (
  registerPasswordForm: Ref<registerPasswordForm | null>
) => {
  const modalStore = useModalStore();

  const { togglePickPassword } = modalStore;

  const { isInitialLoading: isLoading, refetch } = useQuery({
    queryKey: ['resetPassword'],
    queryFn: () => UserService.registerPassword(registerPasswordForm?.value!),
    enabled: false,
    onSuccess: () => {
      togglePickPassword();
    },
    onError: (error) => useError(error),
  });

  return { isLoading, refetch };
};
