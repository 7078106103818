<template>
  <div class="map-wrap">
    <iframe
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A8fb92be59e807942cc89094a9ac706b29786051be18b63634a55ef3411c78a92&amp;source=constructor"
      width="100%"
      height="100%"
      frameborder="0"
    ></iframe>
  </div>
</template>
