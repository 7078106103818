<script setup lang="ts">
  import FormSendMail from '@/components/form/FormSendMail.vue';
  import { useAuthStore } from '@/stores/authStore';
  import FormSendMainIsLogged from '@/views/home/components/FormSendMainIsLogged.vue';
  import { storeToRefs } from 'pinia';

  const authStore = useAuthStore();

  const { isLoggedIn } = storeToRefs(authStore);
</script>
<template>
  <section class="any-questions">
    <v-container>
      <v-row>
        <v-col cols="12" md="6" lg="5">
          <FormSendMail v-if="!isLoggedIn" />
          <FormSendMainIsLogged v-if="isLoggedIn" />
        </v-col>
      </v-row>
    </v-container>

    <img
      class="any-questions__picture"
      loading="lazy"
      src="https://lifehackercoffee.web06.wmstudio.su/assets/img/main/questions-picture.jpg"
      alt="Есть вопросы"
    />
  </section>
</template>
