<script setup lang="ts"></script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="fill-primary"
      d="M10.009 20C12.6882 20 15.2016 18.9618 17.0917 17.0735L16.0441 16.0269C14.4349 17.6347 12.2922 18.5185 10.0118 18.5185C7.7286 18.5185 5.58863 17.6319 3.97944 16.0269C2.37306 14.4192 1.48562 12.2783 1.48562 10C1.48562 7.72166 2.37025 5.58081 3.97944 3.97026C5.58863 2.36532 7.72859 1.48148 10.009 1.48148C12.2894 1.48148 14.4321 2.36532 16.0413 3.97306C17.9988 5.92873 18.6868 7.87318 18.4874 10.9091C18.4144 12.0595 17.4118 12.991 16.2548 12.991C15.2859 12.991 14.4743 12.3092 14.2721 11.4001V5.23569H12.7893V6.71156C12.0001 6.00168 10.9666 5.55556 9.82363 5.55556C7.37193 5.55556 5.37238 7.5505 5.37238 10.0028C5.37238 12.4551 7.36913 14.4501 9.82363 14.4501C11.2166 14.4501 12.4494 13.7935 13.2639 12.7862C13.8817 13.7963 14.9854 14.4753 16.2548 14.4753C18.2122 14.4753 19.841 12.9517 19.9646 11.0073C20.1893 7.53367 19.358 5.1936 17.086 2.92649C15.1988 1.04097 12.6853 0 10.0062 0C7.32981 0 4.81633 1.03816 2.92911 2.92368C1.03909 4.81201 0 7.32323 0 10C0 12.674 1.0419 15.1852 2.92911 17.0735C4.81633 18.959 7.33262 20 10.009 20ZM9.82363 12.9658C8.18636 12.9658 6.8552 11.6358 6.8552 10C6.8552 8.3642 8.18636 7.03423 9.82363 7.03423C11.4581 7.03423 12.7893 8.3642 12.7893 10C12.7893 11.6358 11.4581 12.9658 9.82363 12.9658Z"
      fill="#C06700"
    />
  </svg>
</template>

<style scoped></style>
