import type { VueElement } from 'vue';

export interface MyButtonProps {
  label: string;
  color: MyButtonColor;
  link?: string;
  classNames?: string[] | string;
  disabled?: boolean;
  icon?: VueElement;
  isBell?: boolean;
  isActive?: boolean;
  isBasket?: boolean;
}

export enum MyButtonColor {
  primary = 'primary',
  outlineSecondary = 'outline-secondary',
  white = 'up',
}
