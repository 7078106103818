<script lang="ts" setup>
  import { validateFormMailIsLogged } from '@/helper/validate/schemas';
  import MyInput from '@/components/ui/form/MyInput.vue';
  import TitleEl from '@/components/ui/TitleEl.vue';
  import MyPhoneInput from '@/components/ui/form/MyPhoneInput.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import { ref } from 'vue';
  import MySelect from '@/components/ui/select/MySelect.vue';

  const items = [
    { isInAvailable: 'Товара нет в наличии' },
    { text: 'test', value: '2' },
    { text: '1123', value: '3' },
    { text: '1234', value: '4' },
  ];

  const selected = ref('');

  const onSubmitForm = (data) => alert(JSON.stringify(data));
</script>
<template>
  <Form
    @submit="onSubmitForm"
    :validation-schema="validateFormMailIsLogged"
    class="ask-question"
  >
    <TitleEl class-name="mb-4">Есть вопросы?</TitleEl>
    <p class="bold mb-4">
      Заполните заявку и мы свяжемся с вами в течение 24 часов
    </p>
    <MyInput id="fio" name="fio" placeholder="ФИО *" class="mb-7" />
    <MyPhoneInput placeholder="Телефон *" />
    <MyInput id="city" name="city" placeholder="Город *" class="mb-7 mt-6" />
    <MyInput
      id="orderNumber"
      name="orderNumber"
      placeholder="Номер заказа *"
      class="mb-7"
    />
    <MyInput
      id="dateOrder"
      name="dateOrder"
      placeholder="Дата заказа *"
      class="mb-7"
    />
    <MyInput
      id="questionType"
      name="questionType"
      placeholder="Какая проблема с заказом у вас возникла? *"
      class="mb-7"
    />
    <MySelect
      v-model:selected="selected"
      :options="items"
      is-input
      placeholder="Какая проблема с заказом у вас возникла? *"
    />
    <p class="small pt-1 mb-4">
      Отправляя сообщение, вы соглашаетесь с политикой конфиденциальности сайта
    </p>
    <my-v-btn type="submit" :append-icon="IconArrow"> Отправить </my-v-btn>
  </Form>
</template>
<style scoped></style>
