<script setup lang="ts">
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import { ref } from 'vue';
  import TestImage from '@/components/ui/TestImage.vue';

  interface Props {
    id: number;
    slug: string;
    title: string;
    createdAt: string;
    newsImageLink: string;
  }

  defineProps<Props>();

  const errorLoadedImage = ref<Boolean>(false);
</script>

<template>
  <v-card
    :to="{ name: 'NewsPage', params: { slug } }"
    :class="['card-news rounded-5 position-relative text-black']"
    color="white"
    elevation="0"
    border
    link
  >
    <v-img
      v-if="!errorLoadedImage"
      style="max-height: 360px"
      cover
      class="w-100"
      :src="newsImageLink"
      :alt="slug"
      @error="errorLoadedImage = true"
    />
    <TestImage class="w-100" v-if="errorLoadedImage" />
    <v-card-title class="d-flex align-center card-title pl-6 pr-12 px-6 ma-0">
      {{ title }}
    </v-card-title>
    <my-v-btn
      color="secondary"
      class="pa-0 ma-0 position-absolute"
      height="40"
      width="40"
      variant="outlined"
      :icon-btn="true"
      :to="{ name: 'NewsPage', params: { slug } }"
    >
      <IconArrow class="text-primary" />
    </my-v-btn>
    <v-card-text class="card-data px-6 text-secondary">{{
      createdAt
    }}</v-card-text>
  </v-card>
</template>

<style lang="scss" scoped>
  .v-btn {
    background-color: #fff !important;
    right: 16px;
    bottom: 30px;
  }

  .v-img {
    max-height: 300px;
  }

  .v-card-title {
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 16px;
    text-align: left;
    text-decoration: none;
    height: 63px;
  }

  .v-btn:hover .text-primary {
    color: #95c11f !important;
  }

  .v-card-text {
    color: #b0b0b0 !important;
  }
</style>
