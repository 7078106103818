import { useQuery } from '@tanstack/vue-query';
import { MainService } from '@/services/main.service';

export const useLimitedNews = () => {
  const {
    data: news,
    isSuccess,
    isLoading,
  } = useQuery({
    queryKey: ['limitedNews'],
    queryFn: () => MainService.getLimitedNews(),
    staleTime: 10000,
    keepPreviousData: true,
    select: (data) => data.data.data,
  });

  return { news, isSuccess, isLoading };
};
