<script setup lang="ts">
  import { useHeaderStore } from '@/stores/headerStore';
  import { storeToRefs } from 'pinia';
  import { onUpdated } from 'vue';
  import { toggleBodyScroll } from '@/helper/DOM';

  const store = useHeaderStore();

  const { menuVisibility } = storeToRefs(store);

  const { changeVisibility } = store;

  onUpdated(() => toggleBodyScroll(menuVisibility.value));
</script>
<template>
  <button
    @click="changeVisibility"
    class="btn-burger"
    :class="[{ active: menuVisibility }]"
  >
    burger
  </button>
</template>

<style lang="scss">
  .btn-burger.active {
    background-image: url('@/assets/img/close.svg');
  }
</style>
