<script setup lang="ts">
  import ModalRoot from '@/components/modals/ModalRoot.vue';
  import { Form } from 'vee-validate';
  import { ref } from 'vue';
  import { validateFormEnterCode } from '@/helper/validate/schemas';
  import { useRoute } from 'vue-router';
  import { useAuthByToken } from '@/hooks/queries/user/useAuth';
  import { useResetPasswordByToken } from '@/hooks/queries/user/useResetPassword';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import MyInput from '@/components/ui/form/MyInput.vue';

  interface Props {
    open: boolean;
  }

  defineProps<Props>();

  const formData = ref<any>(null);

  const { query } = useRoute();

  const { refetchByToken, isLoadingByToken } = useAuthByToken(formData);

  const { isLoadingReset, refetchReset } = useResetPasswordByToken(formData);

  const onSubmitEnterCode = async (data) => {
    if (query.email || query.phone) {
      if (query.email) {
        formData.value = {
          email: query.email,
          reset_token: data.reset_token,
        };
      }
      if (query.phone) {
        formData.value = {
          phone: query.phone,
          reset_token: data.reset_token,
        };
      }

      await refetchReset();
    } else {
      formData.value = data;

      await refetchByToken();
    }
  };
</script>

<template>
  <ModalRoot title="Введите код" :open="open" :is-first-step="false">
    <Form
      @submit="onSubmitEnterCode"
      :validation-schema="validateFormEnterCode"
    >
      <div class="modal-body">
        <MyInput
          id="sentCode"
          name="reset_token"
          label="Мы отправили код на номер: "
        />
        <my-v-btn color="text" variant="text"> Отправить код еще раз </my-v-btn>
      </div>
      <div class="modal-footer justify-content-start">
        <div class="d-flex align-items-center justify-content-center w-100">
          <my-v-btn
            type="submit"
            :loading="isLoadingByToken || isLoadingReset"
            :append-icon="IconArrow"
          >
            Создать пароль
          </my-v-btn>
        </div>
      </div>
    </Form>
  </ModalRoot>
</template>
