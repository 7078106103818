<script setup lang="ts">
  import { useOfferStore } from '@/stores/catalogStore';
  import { storeToRefs } from 'pinia';

  const store = useOfferStore();

  const { getIsInCartLength } = storeToRefs(store);
</script>
<template>
  <router-link
    to="/basket"
    class="btn-icon btn-icon-secondary btn-icon-lg basket basketLinkJs text-wrap"
  >
    <span class="text">Корзина</span>
    <span v-if="getIsInCartLength" class="count-text">{{
      getIsInCartLength
    }}</span>
  </router-link>
</template>
