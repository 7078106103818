import { useQuery } from '@tanstack/vue-query';
import { UserService } from '@/services/user.service';
import { useAuthStore } from '@/stores/authStore';

export const useLogout = () => {
  const authStore = useAuthStore();

  const { logout } = authStore;

  const { refetch } = useQuery({
    queryKey: ['logout'],
    queryFn: () => UserService.logout(),
    enabled: false,
    onSuccess: () => logout(),
  });

  return { refetch };
};
