<script setup lang="ts">
  import { Form } from 'vee-validate';
  import { validateFormPickPassword } from '@/helper/validate/schemas';
  import { ref } from 'vue';
  import { useRegisterPassword } from '@/hooks/queries/user/useRegisterPassword';
  import ModalRoot from '@/components/modals/ModalRoot.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import MyInput from '@/components/ui/form/MyInput.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import type { registerPasswordForm } from '@/services/types/dataTypes';

  interface Props {
    open: boolean;
  }

  defineProps<Props>();

  const formData = ref<registerPasswordForm | null>(null);

  const { isLoading, refetch } = useRegisterPassword(formData);

  const onSubmitPickPassword = async (data) => {
    formData.value = data;

    await refetch();
  };
</script>

<template>
  <ModalRoot title="Придумайте пароль" :open="open">
    <Form
      v-slot="{ meta }"
      @submit="onSubmitPickPassword"
      :validation-schema="validateFormPickPassword"
    >
      <div class="modal-body">
        <MyInput type="password" id="password" name="password" label="Пароль" />
      </div>
      <div class="modal-footer justify-content-start pt-2">
        <div class="d-flex w-100 justify-content-center mb-4">
          <my-v-btn
            type="submit"
            :disabled="!(meta.valid && meta.dirty)"
            :loading="isLoading"
            :append-icon="IconArrow"
          >
            Сохранить
          </my-v-btn>
        </div>
      </div>
    </Form>
  </ModalRoot>
</template>
