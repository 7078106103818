import { useAlertStore } from '@/stores/alertStore';

export const useError = (error) => {
  const store = useAlertStore();
  const { setAlertText, showAlert } = store;
  console.log(error);
  if (error.message) {
    setAlertText(error.message);
    showAlert();
  } else {
    setAlertText('Неизвестная ошибка');
    showAlert();
  }
};
