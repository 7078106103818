import type { Ref } from 'vue';
import type { regForm } from '@/services/types/dataTypes';
import { useModalStore } from '@/stores/modalStore';
import { useQuery } from '@tanstack/vue-query';
import { UserService } from '@/services/user.service';
import { useError } from '@/hooks/useError';

export const useRegister = (regForm?: Ref<regForm | null>) => {
  const modalStore = useModalStore();

  const { toggleEnterCodeIsOpen } = modalStore;

  const { isInitialLoading: isLoading, refetch } = useQuery({
    queryKey: ['register'],
    queryFn: () => UserService.register(regForm?.value!),
    enabled: false,
    onSuccess: () => {
      toggleEnterCodeIsOpen();
    },
    onError: (error) => useError(error),
  });

  return { isLoading, refetch };
};
