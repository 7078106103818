import { defineStore } from 'pinia';
import { ref, watch } from 'vue';

export const useAlertStore = defineStore('alertStore', () => {
  const visibility = ref<boolean>(false);
  const errorMessage = ref<string | null>(null);

  const hideAlert = () => (visibility.value = false);
  const showAlert = () => (visibility.value = true);
  const setAlertText = (text: string) => (errorMessage.value = text);
  const clearAlertText = () => (errorMessage.value = null);

  const autoHideAlert = () =>
    setTimeout(() => {
      hideAlert();
      clearAlertText();
    }, 10000);

  watch(visibility, (visibilityValue) => {
    visibilityValue ? autoHideAlert() : clearTimeout(autoHideAlert());
  });

  return { visibility, errorMessage, showAlert, hideAlert, setAlertText };
});
