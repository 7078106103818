import { useQuery } from '@tanstack/vue-query';
import { MainService } from '@/services/main.service';

export const useLimitedOffer = () => {
  const {
    data: offers,
    isLoading,
    isSuccess,
  } = useQuery({
    queryKey: ['limitedOffers'],
    queryFn: () => MainService.getLimitedOffers(),
    staleTime: 10000,
    keepPreviousData: true,
    select: (data) => data.data.data,
  });

  return { offers, isLoading, isSuccess };
};
