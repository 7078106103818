<script setup lang="ts">
  import { useAlertStore } from '@/stores/alertStore';
  import { storeToRefs } from 'pinia';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  const store = useAlertStore();

  const { errorMessage, visibility } = storeToRefs(store);
</script>

<template>
  <v-alert
    style="right: 20px; bottom: 20px; z-index: 10000000"
    class="text-center"
    position="fixed"
    :close-icon="IconArrow"
    closable
    v-if="visibility"
    type="error"
    >{{ errorMessage }}</v-alert
  >
</template>
