<script setup lang="ts">
  import HeaderForm from '@/components/ui/header/components/HeaderForm.vue';
  import { useHeaderStore } from '@/stores/headerStore';
  import { storeToRefs } from 'pinia';
  import { useCatalog } from '@/hooks/queries/catalog/useCatalog';
  import { useCatalogStore } from '@/stores/catalogStore';

  const { catalog } = useCatalog();

  const store = useCatalogStore();
  const headerStore = useHeaderStore();

  const { activeCategory } = storeToRefs(store);

  const { menuVisibility } = storeToRefs(headerStore);

  const navItems = [
    {
      to: '/reviews-company',
      title: 'Отзывы о компании',
    },
    {
      to: '/contacts',
      title: 'Контакты',
    },
    {
      to: '/privacy-policy',
      title: 'Политика конфиденциальности',
    },
    {
      to: '/favourites-contract',
      title: 'Оферта',
    },
    {
      to: '/news',
      title: 'Новости',
    },
  ];
</script>

<template>
  <VNavigationDrawer
    v-model="menuVisibility"
    elevation="0"
    border="none"
    rail
    class="mobile-sidebar w-100 bg-white pb-7"
    :disable-resize-watcher="true"
  >
    <v-container class="header__menu">
      <HeaderForm />
      <v-row class="w-100">
        <v-col cols="12" md="4">
          <v-list
            v-if="!!catalog"
            v-model:selected="activeCategory"
            class="header__nav"
          >
            <v-list-item
              title="Каталог"
              value="all_products"
              class="link-primary big bold"
              active-color="primary"
              width="100%"
            />
            <v-list-item
              v-for="category in catalog.categories"
              :key="category.id"
              :title="category.name"
              :value="String(category.id)"
              class="link-primary big bold"
              active-color="primary"
              width="100%"
            />
          </v-list>
        </v-col>
        <v-col cols="12" md="4">
          <v-list class="header__nav d-flex flex-column">
            <v-list-item
              v-for="item in navItems"
              :key="item.title"
              :title="item.title"
              :to="item.to"
              class="link-primary big bold"
            />
          </v-list>
        </v-col>
        <v-col cols="12" md="4">
          <v-list class="header__social">
            <v-list-item>
              <a
                style="
                  background-image: url('@/assets/img/telegram-danger.svg');
                "
                href="#"
              >
                telegram
              </a>
            </v-list-item>
            <v-list-item>
              <a
                style="
                  background-image: url('@/assets/img/whatsapp-danger.svg');
                "
                href="#"
              >
                whatsApp
              </a>
            </v-list-item>
            <v-list-item>
              <a
                style="
                  background-image: url('@/assets/img/vkontakte-danger.svg');
                "
                href="#"
              >
                vkontakte
              </a>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-container>
  </VNavigationDrawer>
</template>

<style lang="scss">
  .mobile-sidebar {
    height: 100vh !important;
    overflow: hidden !important;
  }

  .header__menu {
    padding-top: 25px;
    @media (min-width: 575px) {
      padding-top: 70px;
    }
  }

  .mobile-sidebar__btn {
    position: absolute;
    right: 150px;
  }
</style>
