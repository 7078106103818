<script setup lang="ts"></script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="path-1-inside-1_766_12968" fill="white">
      <path
        d="M14.3636 4H11.6591V3.68C11.6591 1.648 10.0205 0 8 0C5.97955 0 4.34091 1.648 4.34091 3.68V4H1.63636C1.28437 4 1 4.286 1 4.64V15.36C1 15.714 1.28437 16 1.63636 16H14.3636C14.7156 16 15 15.714 15 15.36V4.64C15 4.286 14.7156 4 14.3636 4ZM10.2273 4H5.77273V3.68C5.77273 2.442 6.76903 1.44 8 1.44C9.23097 1.44 10.2273 2.442 10.2273 3.68V4Z"
      />
    </mask>
    <path
      class="fill-primary"
      d="M11.6591 4H10.1591V5.5H11.6591V4ZM4.34091 4V5.5H5.84091V4H4.34091ZM10.2273 4V5.5H11.7273V4H10.2273ZM5.77273 4H4.27273V5.5H5.77273V4ZM14.3636 2.5H11.6591V5.5H14.3636V2.5ZM13.1591 4V3.68H10.1591V4H13.1591ZM13.1591 3.68C13.1591 0.827642 10.8569 -1.5 8 -1.5V1.5C9.18398 1.5 10.1591 2.46836 10.1591 3.68H13.1591ZM8 -1.5C5.14307 -1.5 2.84091 0.827642 2.84091 3.68H5.84091C5.84091 2.46836 6.81602 1.5 8 1.5V-1.5ZM2.84091 3.68V4H5.84091V3.68H2.84091ZM4.34091 2.5H1.63636V5.5H4.34091V2.5ZM1.63636 2.5C0.447901 2.5 -0.5 3.46564 -0.5 4.64H2.5C2.5 5.10636 2.12085 5.5 1.63636 5.5V2.5ZM-0.5 4.64V15.36H2.5V4.64H-0.5ZM-0.5 15.36C-0.5 16.5344 0.4479 17.5 1.63636 17.5V14.5C2.12085 14.5 2.5 14.8936 2.5 15.36H-0.5ZM1.63636 17.5H14.3636V14.5H1.63636V17.5ZM14.3636 17.5C15.5521 17.5 16.5 16.5344 16.5 15.36H13.5C13.5 14.8936 13.8792 14.5 14.3636 14.5V17.5ZM16.5 15.36V4.64H13.5V15.36H16.5ZM16.5 4.64C16.5 3.46564 15.5521 2.5 14.3636 2.5V5.5C13.8792 5.5 13.5 5.10636 13.5 4.64H16.5ZM10.2273 2.5H5.77273V5.5H10.2273V2.5ZM7.27273 4V3.68H4.27273V4H7.27273ZM7.27273 3.68C7.27273 3.26236 7.60551 2.94 8 2.94V-0.0600002C5.93256 -0.0600002 4.27273 1.62164 4.27273 3.68H7.27273ZM8 2.94C8.39449 2.94 8.72727 3.26236 8.72727 3.68H11.7273C11.7273 1.62164 10.0674 -0.0600002 8 -0.0600002V2.94ZM8.72727 3.68V4H11.7273V3.68H8.72727Z"
      fill="#C06700"
      mask="url(#path-1-inside-1_766_12968)"
    />
  </svg>
</template>

<style scoped></style>
