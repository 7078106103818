<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5932 10.8122C23.1356 11.5217 23.1356 12.4795 22.5932 13.1878C20.8847 15.4181 16.7857 20.0103 12.0002 20.0103C7.21461 20.0103 3.11563 15.4181 1.40716 13.1878C1.14326 12.8481 1 12.4302 1 12C1 11.5698 1.14326 11.1519 1.40716 10.8122C3.11563 8.58189 7.21461 3.98972 12.0002 3.98972C16.7857 3.98972 20.8847 8.58189 22.5932 10.8122V10.8122Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0004 15.433C13.8963 15.433 15.4333 13.896 15.4333 12C15.4333 10.104 13.8963 8.56702 12.0004 8.56702C10.1044 8.56702 8.56738 10.104 8.56738 12C8.56738 13.896 10.1044 15.433 12.0004 15.433Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
