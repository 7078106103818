<template>
  <v-col cols="12" md="5">
    <form action="#" class="site-search">
      <label for="siteSearch">
        <input id="siteSearch" type="text" placeholder="Поиск" />
        <input type="submit" />
      </label>
      <div class="site-search__dropdown">
        <ul class="site-search__dropdown--list">
          <li><a href="#">111</a></li>
          <li><a href="#">222</a></li>
        </ul>
      </div>
    </form>
  </v-col>
</template>
