<script setup lang="ts">
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import { computed, VueElement } from 'vue';
  import { MyButtonColor } from '@/types/ui/MyButtonUI';
  import MySpinner from '../MySpinner.vue';

  interface Props {
    label: string;
    color: MyButtonColor;
    link?: string;
    classNames?: string[] | string;
    disabled?: boolean;
    icon?: VueElement;
    isBell?: boolean;
    isActive?: boolean;
    isBasket?: boolean;
    isSubmit?: boolean;
    isLoading?: boolean;
  }

  interface Emits {
    (e: 'click'): void;
  }

  const props = defineProps<Props>();

  const emit = defineEmits<Emits>();

  const handleClick = () => emit('click');

  const isPrimary = computed(() => props.color === MyButtonColor.primary);
</script>

<template>
  <!-- link template -->
  <router-link
    v-if="link"
    :class="['btn', `btn-${color}`, classNames, { 'is-active': isActive }]"
    :to="link"
  >
    <template v-if="icon">
      <span v-if="isBasket" class="ic basket-ic"></span>
      <component :is="icon" />
      {{ label }}
    </template>
    <template v-if="!isBasket && !icon">
      <span :class="[{ 'me-4': isPrimary }]">{{ label }}</span>
      <icon-arrow v-if="isPrimary" />
    </template>
  </router-link>

  <!-- button template -->
  <button
    v-if="!link"
    :class="['btn', `btn-${color}`, classNames, { 'is-active': isActive }]"
    :disabled="disabled"
    :type="isSubmit ? 'submit' : 'button'"
    @click="handleClick"
  >
    <template v-if="icon">
      <span v-if="isBasket" class="ic basket-ic"></span>
      <component :is="icon" />
      {{ label }}
    </template>
    <template v-if="!isBasket && !icon">
      <span :class="[{ 'me-4': isPrimary }]">{{ label }}</span>
      <icon-arrow v-if="isPrimary && !isLoading" />
      <MySpinner v-if="isLoading" />
    </template>
  </button>
</template>
