import * as yup from 'yup';

export const requiredMessage: string = 'Поле обязательно к заполнению';
export const emailMessage: string =
  'Поле Email должно быть действительным электронным адресом.';

export const errorPhoneMessage: string = 'Неверный формат телефона';

export const stringTypeError: string = 'Должно быть строкой';

export const requiredString = yup.string().required(requiredMessage);

export const requiredStringTypeCheck = yup
  .string()
  .required(requiredMessage)
  .matches(/\D+$/, stringTypeError);

export const requiredPhone = yup
  .string()
  .required(requiredMessage)
  .min(17, errorPhoneMessage);

export const requiredEmail = yup
  .string()
  .email(emailMessage)
  .required(requiredMessage);

export const confirmPassword = yup
  .string()
  .oneOf([yup.ref('password'), null], 'Пароли не совпадают');
