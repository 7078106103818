<script setup lang="ts">
  import { defineAsyncComponent } from 'vue';
  import TitleEl from '@/components/ui/TitleEl.vue';
  import { carouselEnum } from '@/types/carousel';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import { useLimitedOffer } from '@/hooks/queries/main/useLimitedOffer';

  const MyCarousel = defineAsyncComponent(
    () => import('@/components/carousel/MyCarousel.vue')
  );

  const { offers, isSuccess } = useLimitedOffer();
</script>

<template>
  <section class="product-section nav-top-right">
    <template v-if="isSuccess && offers.length !== 0">
      <v-container>
        <TitleEl>Наша продукция</TitleEl>
      </v-container>
      <v-container>
        <MyCarousel :height="500" :type="carouselEnum.main" />
        <v-sheet class="text-center">
          <my-v-btn to="/catalog" :append-icon="IconArrow">
            Все товары
          </my-v-btn>
        </v-sheet>
      </v-container>
    </template>
  </section>
</template>
