<script setup lang="ts"></script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_732_8469)">
      <path
        class="stroke-primary"
        d="M16.418 17.5904L13.4683 19.0242C13.0291 19.2468 12.5594 19.2126 12.1393 19.0698C10.3014 18.3804 8.85745 16.8692 7.73596 15.4407C5.95774 12.9123 4.51554 10.0376 4.03342 6.99385C3.88002 5.73417 3.73788 4.1452 4.20841 2.9582C4.3686 2.50593 4.64305 2.10208 5.06271 1.91855L8.01806 0.468155C8.60849 0.235113 9.00508 0.52072 9.14316 1.07403L9.7686 5.04083C9.81926 5.50278 9.56658 5.89296 9.19106 6.08252L8.10225 6.61687C8.0287 6.66842 7.97011 6.73568 7.94028 6.82046C7.84029 8.13179 8.40433 9.56441 8.97661 10.6132C9.54887 11.6619 10.091 13.0032 11.1698 13.6451C11.2998 13.7342 11.471 13.8084 11.6064 13.7479L12.8787 13.1098C13.2885 12.9741 13.7997 13.014 14.0784 13.3344L14.095 13.34L16.6483 16.3193C17.0173 16.7737 16.8722 17.3591 16.418 17.5904Z"
        stroke="#C06700"
        stroke-width="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_732_8469">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
