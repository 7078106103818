import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from '@/App.vue';
import router from '@/router';

import '@/assets/scss/app.scss';
import { vuetify } from '@/plugins/vuetify';

import { VueQueryPlugin, vueQueryPluginOptions } from '@/plugins/vueQuery';

const app = createApp(App);

app.use(vuetify);
app.use(createPinia());
app.use(router);
app.use(VueQueryPlugin, vueQueryPluginOptions);

app.mount('#app');
