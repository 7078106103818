import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';

export const useAuthStore = defineStore('authStore', () => {
  const userToken = ref(useLocalStorage('token', { token: null }));

  const isLoggedIn = computed(() => !!userToken.value.token);

  const setUserToken = (token) => {
    console.log(token);
    return (userToken.value.token = token);
  };

  const logout = () => (userToken.value.token = null);
  // const { execute } = logoutUser();
  //
  // execute('/logout', { method: 'POST' }).then((res) => {
  //   if (res.error.value) {
  //     userToken.value.token = null;
  //     return useError(res.error);
  //   }

  return { userToken, isLoggedIn, setUserToken, logout };
});
