import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';
import { useModalStore } from '@/stores/modalStore';
import HomeView from '@/views/home/HomeView.vue';

// lazy import
const ContactsView = () => import('@/views/ContactsView.vue');
const PrivacyPolicy = () => import('@/views/privacyPolicy/PrivacyPolicy.vue');
const OfferContracts = () =>
  import('@/views/offerContracts/OfferContracts.vue');

const NewsView = () => import('@/views/NewsView.vue');

const ReviewsCompanyView = () =>
  import('@/views/reviewsCompany/ReviewsCompanyView.vue');

const ProductCardView = () => import('@/views/productCard/ProductCardView.vue');

const CatalogView = () => import('@/views/catalog/CatalogView.vue');

const NewsCardView = () => import('@/views/newsCard/NewsCardView.vue');

const MyProfileView = () => import('@/views/myProfile/MyProfileView.vue');

const FavouritesView = () => import('@/views/favourites/FavouritesView.vue');

const BasketView = () => import('@/views/basket/BasketView.vue');

const LegalEntity = () => import('@/views/legalEntity/LegalEntityView.vue');

const HowGetDiscount = () =>
  import('@/views/howGetDiscount/HowGetDiscount.vue');

const Settings = () => import('@/views/settings/SettingsView.vue');

const NotFound = () => import('@/views/404/NotFound.vue');

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
    },
    {
      path: '/catalog',
      name: 'catalog',
      component: CatalogView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Каталог',
            to: '/catalog',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/catalog/:id',
      name: 'OfferView',
      component: ProductCardView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Каталог',
            to: '/catalog',
          },
          {
            name: ':id',
            to: '#',
            class: 'active',
          },
        ],
        search: true,
      },
    },
    {
      path: '/contacts',
      name: 'ContactsView',
      component: ContactsView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Контакты',
            to: '#',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Политика конфиденциальности',
            to: '/privacy-policy',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/favourites-contract',
      name: 'OfferContracts',
      component: OfferContracts,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Оферта',
            to: '/favourites-contract',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/news/:slug',
      name: 'NewsPage',
      component: NewsCardView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Новости и события',
            to: '/news',
          },
          {
            name: ':slug',
            to: '#',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/news',
      name: 'NewsView',
      component: NewsView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Новости и события',
            to: '#',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/reviews-company',
      name: 'ReviewsCompany',
      component: ReviewsCompanyView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Отзывы о компании',
            to: '/reviews-company',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/account',
      name: 'MyProfile',
      component: MyProfileView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Личный кабинет',
            to: '/account',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/favourites',
      name: 'Favourites',
      component: FavouritesView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Избранное',
            to: '/favourites',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/basket',
      name: 'Basket',
      component: BasketView,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Корзина',
            to: '/basket',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/legal-entity/create',
      name: 'LegalEntityCreate',
      component: LegalEntity,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Личный кабинет',
            to: '/account',
          },
          {
            name: 'Добавить компанию',
            to: '/legal-entity/create',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/legal-entity/edit/:id',
      name: 'LegalEntityEdit',
      component: LegalEntity,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Личный кабинет',
            to: '/account',
          },
          {
            name: 'Обновить компанию',
            to: '/legal-entity/edit',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/how-get-discount',
      name: 'HowGetDiscount',
      component: HowGetDiscount,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Как получить скидку?',
            to: '/how-get-discount',
            class: 'active',
          },
        ],
      },
    },
    {
      path: '/user/edit',
      name: 'Settings',
      component: Settings,
      meta: {
        breadCrumbs: [
          {
            name: 'Главная',
            to: '/',
          },
          {
            name: 'Личный кабинет',
            to: '/account',
          },
          {
            name: 'Настройки профиля',
            to: '/user/edit',
            class: 'active',
          },
        ],
      },
    },
    { path: '/:pathMatch(.*)*', component: NotFound },
  ],
  scrollBehavior() {
    return { top: 0, behavior: 'auto' };
  },
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const modalStore = useModalStore();

  const { isLoggedIn } = authStore;
  const { toggleAuthIsOpen } = modalStore;

  const protectedRoutes = [
    'Settings',
    'LegalEntityCreate',
    'Favourites',
    'MyProfile',
  ];

  const showModalAuth = () => {
    toggleAuthIsOpen();
    next(from.fullPath);
  };

  if (!isLoggedIn) {
    protectedRoutes.forEach((route) => {
      to.name === route ? showModalAuth() : null;
    });
  }

  next();
});

export default router;
