import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useBreadCrumbStore = defineStore('breadCrumbStore', () => {
  const dynamicRoute = ref(null);

  const setDynamicRoute = (route) => (dynamicRoute.value = route);

  return { dynamicRoute, setDynamicRoute };
});
