<script setup lang="ts">
  import { Form } from 'vee-validate';
  import { validateFormMail } from '@/helper/validate/schemas';
  import TitleEl from '../ui/TitleEl.vue';
  import PhoneInput from '@/components/ui/form/MyPhoneInput.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import MyInput from '@/components/ui/form/MyInput.vue';
  const onSubmitForm = (data) => console.log(data);
</script>

<template>
  <Form
    @submit="onSubmitForm"
    :validation-schema="validateFormMail"
    class="ask-question"
  >
    <div v-if="$route.path === '/'">
      <TitleEl class-name="mb-4">Есть вопросы?</TitleEl>
      <p class="bold mb-4">
        Заполните заявку и мы свяжемся с вами в течение 24 часов
      </p>
    </div>
    <div v-else>
      <p class="h3">Есть вопросы?</p>
      <p class="mb-32 bold">
        Заполните заявку и мы свяжемся с вами в течение 24 часов
      </p>
    </div>
    <MyInput id="name" name="name" placeholder="Ваше имя" class="mb-7" />
    <MyInput id="email" name="email" placeholder="E-mail" class="mb-7" />
    <PhoneInput />
    <MyInput
      id="comment"
      name="comment"
      placeholder="Комментарий"
      as="textarea"
      class="mt-7"
    />
    <p class="small pt-1 mb-4">
      Отправляя сообщение, вы соглашаетесь с политикой конфиденциальности сайта
    </p>
    <my-v-btn type="submit" :append-icon="IconArrow"> Отправить </my-v-btn>
  </Form>
</template>
