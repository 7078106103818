<script setup lang="ts">
  type variant = 'flat' | 'elevated' | 'tonal' | 'outlined' | 'text' | 'plain';
  type colors = 'primary' | 'secondary';

  interface Props {
    height?: string;
    width?: string;
    to?: string | object;
    color?: colors;
    appendIcon?: any;
    appendIconBefore?: any;
    icon?: boolean;
    variant?: variant;
    loading?: boolean;
    elevation?: number;
    textWrap?: boolean;
    block?: boolean;
  }

  interface Emits {
    (e: 'click'): void;
  }

  withDefaults(defineProps<Props>(), {
    height: '60',
    color: 'primary',
    variant: 'elevated',
  });

  const emit = defineEmits<Emits>();

  const handleClick = () => emit('click');
</script>

<template>
  <v-btn
    class="d-inline-flex align-center justify-center"
    :class="['btn-' + color, { 'text-wrap': textWrap }]"
    :color="color"
    :to="to"
    :height="height"
    :size="width"
    :variant="variant"
    :append-icon="appendIcon"
    :icon="icon"
    :elevation="elevation"
    :loading="loading"
    :block="block"
    @click="handleClick"
  >
    <component :is="appendIconBefore" />
    <slot />
  </v-btn>
</template>

<style lang="scss" scoped>
  .v-btn {
    font-size: 16px;
    line-height: 22px;
    font-weight: 500;
    padding: 18px 30px;
    margin-bottom: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    outline: none;
    text-align: center;
    text-transform: none;
    letter-spacing: normal;

    &.btn-up {
      font-weight: 700 !important;
      font-size: 14px !important;
      line-height: 78% !important;

      .v-btn__content {
        color: #c06700 !important;
      }

      &:hover {
        background-color: #fff;
        box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
          0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12) !important;
      }
    }
  }

  .btn-primary {
    box-shadow: -4px 5px #a53f1e;

    &:hover {
      background-color: #95c11f !important;
      border-color: #95c11f !important;
      box-shadow: none !important;
    }
  }

  .btn-secondary {
    transition: all 0.15s ease-in-out;
    &:hover {
      border-color: #95c11f;
    }
    .v-btn__content {
      color: #454443 !important;
    }
  }
</style>
