interface IInfoInline {
  address?: string;
  phone_number?: string;
  email?: string;
}

export const infoInlineData: IInfoInline = {
  address: 'Москва, район Южное Бутово, Синельниковская улица, д. 12',
  phone_number: '8 (800) 775-17-89',
  email: 'order.lifehacker@gmail.com',
};
