import type { IFooterData } from '../../../types/footer';

export const footerNavFirst: IFooterData[] = [
  {
    to: '/news',
    text: 'Новости',
  },
  {
    to: '/reviews-company',
    text: 'Отзывы о компании',
  },
  {
    to: '/contacts',
    text: 'Контакты',
  },
];

export const footerNavSecond: IFooterData[] = [
  {
    to: '/privacy-policy',
    text: 'Политика конфиденциальности',
  },
  {
    to: '/favourites-contract',
    text: 'Офферта',
  },
];
