import { useAxios } from '@vueuse/integrations/useAxios';
import instance from '@/utils/axios';
import type { IGetFavourites } from '@/services/types/IGetFavourites';
import type { IGetQuantityForFavourites } from '@/services/types/IGetQuantityForFavourites';

export const OfferService = {
  getFavourites() {
    return instance.get<IGetFavourites>('/favourites');
  },
  addToFavourites(offer_id: number) {
    return instance.post<IGetQuantityForFavourites>('/favourites', {
      offer_id,
    });
  },
  removeFromFavourites(offer_id: number) {
    return instance.post<IGetQuantityForFavourites>('/favourites/remove', {
      offer_id,
    });
  },
};

export const updateFavorites = () => {
  return useAxios('', { method: 'PUT' }, instance, {
    immediate: false,
  });
};

export const updateIsInCart = () => {
  return useAxios('', { method: 'PUT' }, instance, {
    immediate: false,
  });
};

export const getOfferById = (id: string | string[]) => {
  return useAxios(`/offers/${id}`, instance);
};
