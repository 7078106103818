<script setup lang="ts">
  import ModalRootHeader from './ModalRootHeader.vue';
  import { toggleBodyScroll } from '@/helper/DOM';
  import { onUpdated } from 'vue';

  interface Props {
    title: string;
    open: boolean;
    isFirstStep?: boolean;
  }

  interface Emits {
    (e: 'closeModal');
    (e: 'prevStep');
  }

  const props = withDefaults(defineProps<Props>(), {
    isFirstStep: true,
  });

  defineEmits<Emits>();

  onUpdated(() => toggleBodyScroll(props.open));
</script>
<template>
  <Teleport to="body">
    <Transition name="fade">
      <div
        v-show="open"
        class="modal"
        :class="{ showed: open }"
        style="display: block"
        tabindex="-1"
        role="dialog"
      >
        <Transition name="drop-in">
          <div v-show="open" class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <ModalRootHeader
                :title="title"
                :isFirstStep="isFirstStep"
                @closeModal="$emit('closeModal')"
                @prevStep="$emit('prevStep')"
              />
              <slot />
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss">
  .modal {
    background-color: rgba(68, 68, 67, 0.5);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .drop-in-enter-active,
  .drop-in-leave-active {
    transition: all 0.3s ease-out;
  }

  .drop-in-enter-from,
  .drop-in-leave-to {
    opacity: 0;
    transform: translateY(-15%);
  }
</style>
