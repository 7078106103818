<script setup lang="ts">
  import RegModal from '../RegModal.vue';
  import { storeToRefs } from 'pinia';
  import { useModalStore } from '@/stores/modalStore';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';

  const store = useModalStore();

  const { registerIsOpen } = storeToRefs(store);

  const { toggleRegIsOpen, toggleAuthIsOpen } = store;
</script>

<template>
  <my-v-btn
    :append-icon="IconArrow"
    width="100%"
    color="secondary"
    variant="outlined"
    @click="toggleRegIsOpen"
  >
    Регистрация
  </my-v-btn>

  <RegModal
    :open="registerIsOpen"
    @closeModal="toggleRegIsOpen"
    @prevStep="toggleAuthIsOpen"
  />
</template>
