<script setup lang="ts">
  import TitleEl from '@/components/ui/TitleEl.vue';
  import NewsList from '@/components/news/NewsLimitedList.vue';
  import IconBell from '@/components/icons/IconBell.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import MyLoader from '@/components/ui/MyLoader.vue';
  import { useLimitedNews } from '@/hooks/queries/main/useLimitedNews';

  const { news, isLoading, isSuccess } = useLimitedNews();
</script>

<template>
  <section class="news-section">
    <v-container>
      <template v-if="isSuccess && news.length !== 0">
        <v-sheet
          class="d-flex flex-wrap align-center justify-space-between mb-32"
        >
          <TitleEl>Новости и события</TitleEl>
          <my-v-btn
            color="secondary"
            variant="outlined"
            :append-icon-before="IconBell"
          >
            Подписаться на новости
          </my-v-btn>
        </v-sheet>
        <NewsList :news="news" />
        <v-sheet class="text-center">
          <my-v-btn to="/news" :append-icon="IconArrow"> Все новости </my-v-btn>
        </v-sheet>
      </template>
      <MyLoader v-if="isLoading" />
    </v-container>
  </section>
</template>
