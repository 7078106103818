import { VueQueryPlugin } from '@tanstack/vue-query';
import type { VueQueryPluginOptions } from '@tanstack/vue-query';
import { useError } from '@/hooks/useError';

const vueQueryPluginOptions: VueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        onError: (error) => useError(error),
      },
      mutations: {
        retry: false,
        onError: (error) => useError(error),
      },
    },
  },
};

export { VueQueryPlugin, vueQueryPluginOptions };
