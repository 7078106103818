<script setup lang="ts">
  import { Field } from 'vee-validate';
  import type { IOption } from '@/types/ui/MySelectProps';
  import { computed, ref } from 'vue';
  import type { Types } from '@/services/types/IGetEntityTypes';

  interface Props {
    options: Types;
    selected?: IOption;
    isInput?: boolean;
    label?: string;
    name?: string;

    placeholder?: string;
  }

  interface Emits {
    (e: 'update:selected', value): void;
  }

  const props = defineProps<Props>();

  const emit = defineEmits<Emits>();

  const isActive = ref<boolean>(false);

  const onSelect = (value) => emit('update:selected', value);

  const handleToggleOpen = () => (isActive.value = !isActive.value);

  const getSelectedText = computed(() => {
    return props.selected === undefined
      ? props.options[0].text
      : props.selected.text;
  });

  const getSelectedValue = computed(() => props.selected);
</script>

<template>
  <div v-if="!isInput" class="choices" :class="[{ 'is-open': isActive }]">
    <div class="choices__inner" @click="handleToggleOpen">
      {{ getSelectedText }}
      <ul v-show="isActive" class="choices__list w-100">
        <li
          v-for="(key, text) in options"
          v-show="selected !== key"
          class="choices__item"
          :key="key"
          :value="key"
          @click="onSelect(key)"
        >
          {{ text }}
        </li>
      </ul>
    </div>
  </div>

  <div v-if="isInput" class="select-primary-wrapper">
    <Field type="hidden" :name="name" v-model="getSelectedValue" />
    <label v-if="label" class="form-label" for="typeOrganization">{{
      label
    }}</label>
    <div class="choices w-100" :class="[{ 'is-open': isActive }]">
      <div class="choices__inner h-100" @click="handleToggleOpen">
        <span v-if="placeholder && !options[selected]" style="color: #8c8c8c">{{
          placeholder
        }}</span>
        <span v-else>{{ options[selected] }}</span>
        <ul v-show="isActive" class="choices__list w-100">
          <li
            v-for="(text, key) in options"
            class="choices__item"
            :key="key"
            :value="key"
            @click="onSelect(key)"
          >
            {{ text }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .choices {
    position: relative;
    height: 36px;
    cursor: pointer;
    width: 100%;
    user-select: none;

    &::after {
      content: '';
      height: 8px;
      width: 8px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      background-image: url('../../../assets/img/arrow.svg');
      transform: rotate(90deg);
      right: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
      pointer-events: none;
      transition: all 0.15s ease-in-out;
    }

    &__inner {
      border: 1px solid #ded8d1;
      border-radius: 40px;
      font-size: 14px;
      line-height: 18px;
      padding: 8px 32px 8px 16px;
    }

    &.is-open {
      .choices__inner {
        border-bottom: none;
        border-radius: 20px 20px 0 0;
        color: #95c11f;
      }

      &:after {
        transform: rotate(-90deg);
      }

      .choices__list {
        display: block;
        list-style: none;
      }
    }

    &__list {
      display: none;
      position: absolute;
      top: calc(100% - 1px);
      z-index: 50;
      background: #fff;
      width: 100%;
      padding: 0 16px 8px;
      border-radius: 0 0 20px 20px;
      border: 1px solid #ded8d1;
      border-top: none;
      left: 0;

      .choices__item {
        font-size: 14px;
        line-height: 140%;
        color: #454443;
        padding-bottom: 6px;
        transition: 0.3s;

        &:hover {
          opacity: 0.8;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &.is-selected {
          display: none;
        }
      }
    }
  }

  .select-primary-wrapper {
    .choices {
      &__inner {
        background-color: #fff;
        border: 1px solid #ded8d1;
        border-radius: 5px;
        font-size: 16px;
        line-height: 18px;
        overflow: hidden;
        padding: 18px 32px 18px 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &.is-open {
        .choices__inner {
          border-radius: 5px 5px 0 0;
          color: inherit;
          border: 1px solid #ded8d1;
          border-bottom: none;
        }
      }
      &__list {
        padding: 0;
        top: calc(100%);
        border-radius: 0 0 5px 5px;
        max-height: 230px;
        overflow-y: scroll;
      }

      &__item {
        background-color: #fff;
        border-bottom: 1px solid #ded8d1;
        font-size: 16px;
        line-height: 18px;
        overflow: hidden;
        padding: 18px 32px 18px 16px;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:first-child {
          border-top: 1px solid #ded8d1;
        }
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
</style>
