import type { authForm, resetTokenForm } from '@/services/types/dataTypes';
import type { Ref } from 'vue';
import { useQuery } from '@tanstack/vue-query';
import { UserService } from '@/services/user.service';
import { useError } from '@/hooks/useError';
import { useAuthStore } from '@/stores/authStore';
import { useModalStore } from '@/stores/modalStore';
export const useAuth = (authForm?: Ref<authForm | null>) => {
  const authStore = useAuthStore();

  const modalStore = useModalStore();

  const { setUserToken } = authStore;

  const { toggleAuthIsOpen } = modalStore;

  const { isInitialLoading: isLoading, refetch } = useQuery({
    queryKey: ['auth'],
    queryFn: () => UserService.auth(authForm?.value!),
    enabled: false,
    onSuccess: ({ data }) => {
      toggleAuthIsOpen();
      setTimeout(() => setUserToken(data.data.plainTextToken), 150);
    },
    onError: (error) => useError(error),
  });

  return { isLoading, refetch };
};

export const useAuthByToken = (resetTokenForm: Ref<resetTokenForm>) => {
  const authStore = useAuthStore();

  const modalStore = useModalStore();

  const { setUserToken } = authStore;

  const { toggleEnterCodeIsOpen, togglePickPassword } = modalStore;

  const { isInitialLoading: isLoadingByToken, refetch: refetchByToken } =
    useQuery({
      queryKey: ['authByToken'],
      queryFn: () => UserService.authByToken(resetTokenForm.value),
      enabled: false,
      onSuccess: ({ data }) => {
        toggleEnterCodeIsOpen();
        setTimeout(() => setUserToken(data.token.plainTextToken), 150);
        setTimeout(() => togglePickPassword(), 300);
      },
      onError: (error) => useError(error),
    });

  return { isLoadingByToken, refetchByToken };
};
