<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66699 15.5L8.33366 13.8334H10.0003L11.1337 12.7C12.2921 13.1036 13.5533 13.102 14.7107 12.6955C15.8682 12.2889 16.8534 11.5016 17.505 10.4622C18.1566 9.4228 18.4362 8.193 18.2978 6.97406C18.1595 5.75512 17.6115 4.61924 16.7435 3.75233C15.8755 2.88542 14.7389 2.33883 13.5198 2.20201C12.3007 2.0652 11.0713 2.34627 10.0327 2.99921C8.99413 3.65216 8.20797 4.63831 7.80288 5.79626C7.3978 6.95422 7.39778 8.21538 7.80283 9.37335L1.66699 15.5V18.8333H5.00033L6.66699 17.1667V15.5Z"
      stroke="#C06700"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
    <path
      d="M14.1673 7.16667C14.6276 7.16667 15.0007 6.79357 15.0007 6.33333C15.0007 5.8731 14.6276 5.5 14.1673 5.5C13.7071 5.5 13.334 5.8731 13.334 6.33333C13.334 6.79357 13.7071 7.16667 14.1673 7.16667Z"
      stroke="#C06700"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
</template>
