import { object } from 'yup';
import {
  confirmPassword,
  requiredEmail,
  requiredPhone,
  requiredString,
  requiredStringTypeCheck,
} from '@/helper/validate/variables';

export const validateFormMail = object({
  name: requiredString,
  email: requiredEmail,
  phone: requiredPhone,
  comment: requiredString,
});
export const validateFormMailIsLogged = object({
  fio: requiredString,
  phone: requiredPhone,
  city: requiredString,
  orderNumber: requiredString,
  dateOrder: requiredString,
  questionType: requiredString,
});

export const validateFormAuth = object({
  credentials: requiredString,
  password: requiredString.min(8, 'Минимум 8 символов'),
});

export const validateFormRegister = object({
  credentials: requiredString,
  surname: requiredStringTypeCheck,
  name: requiredStringTypeCheck,
});

export const validateFormResetPassword = object({
  credentials: requiredString,
});

export const validateFormEnterCode = object({
  reset_token: requiredString,
});
export const validateFormPickPassword = object({
  // token: requiredString,
  password: requiredString.min(8, 'Минимум 8 символов'),
});

export const validateFormSettings = object({
  name: requiredStringTypeCheck,
  surname: requiredStringTypeCheck,
  email: requiredEmail,
  phone: requiredPhone,
  password: requiredString,
  repeat_password: confirmPassword,
});
