import instance from '@/utils/axios';
import type { IGetCatalogResponse } from '@/services/types/IGetCatalogResponse';

export const CatalogService = {
  async getCatalog(category, tags, sort, perpage, page, search) {
    return instance.get<IGetCatalogResponse>('/catalog', {
      params: {
        'tags[]': tags,
        category,
        sort,
        perpage,
        page,
        text_search: search,
      },
    });
  },
};
