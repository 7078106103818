<script setup lang="ts">
  import ModalRoot from '@/components/modals/ModalRoot.vue';
  import { Form } from 'vee-validate';
  import { ref } from 'vue';
  import MyInput from '@/components/ui/form/MyInput.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import { validateFormResetPassword } from '@/helper/validate/schemas';
  import { useResetPassword } from '@/hooks/queries/user/useResetPassword';

  interface Props {
    open: boolean;
  }

  defineProps<Props>();

  const formData = ref<any>(null);

  const { isLoading, refetch } = useResetPassword(formData);

  const onSubmitResetPassword = async (data) => {
    const regEmail = /^[\w-.]+@[\w-]+\.[a-z]{2,4}$/i;
    if (regEmail.test(data.credentials)) {
      formData.value = {
        loginType: 'email',
        email: data.credentials,
        phone: data.credentials,
      };
    } else {
      formData.value = {
        loginType: 'phone',
        email: data.credentials,
        phone: data.credentials,
      };
    }
    await refetch();
  };
</script>

<template>
  <ModalRoot title="Восстановление пароля" :open="open" :is-first-step="false">
    <Form
      @submit="onSubmitResetPassword"
      :validation-schema="validateFormResetPassword"
    >
      <div class="modal-body">
        <MyInput id="email5" name="credentials" label="E-mail или телефон" />
      </div>
      <div class="modal-footer justify-content-start">
        <div class="d-flex align-items-center">
          <my-v-btn
            type="submit"
            color="primary"
            variant="elevated"
            :append-icon="IconArrow"
            :loading="isLoading"
          >
            Отправить письмо
          </my-v-btn>
        </div>
      </div>
    </Form>
  </ModalRoot>
</template>
