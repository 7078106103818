<script setup lang="ts">
  import { Field, ErrorMessage } from 'vee-validate';
  import { useIMask } from 'vue-imask';
  import { onBeforeUnmount, ref } from 'vue';

  interface Props {
    isLabel?: boolean;
    name?: string;
    placeholder?: string;
  }

  const phone = ref<string>('+7');
  const currentPhone = ref<string>('+73453453453');

  const { el } = useIMask({
    mask: '{+7}(000) 000-00-00',
  });

  withDefaults(defineProps<Props>(), {
    name: 'phone',
  });

  const onAccept = () => {
    if (el.value.value.length <= 17) {
      currentPhone.value = el.value.value;
    }
  };

  const clearPhone = () => {
    phone.value = '';
    currentPhone.value = '';
  };

  onBeforeUnmount(() => clearPhone());
</script>

<template>
  <div class="form-wrapper" v-if="isLabel">
    <label class="form-label" for="phone">Телефон</label>
    <Field type="hidden" v-model="currentPhone" :name="name" />
    <input ref="el" class="form-control" @input="onAccept" />
    <v-slide-y-reverse-transition leave-absolute>
      <ErrorMessage class="validate-error" name="phone" />
    </v-slide-y-reverse-transition>
  </div>
  <div class="form-wrapper" v-if="!isLabel">
    <Field type="hidden" v-model="currentPhone" :name="name" />
    <input
      v-model="phone"
      ref="el"
      class="form-control"
      :placeholder="placeholder ? placeholder : 'Телефон'"
      @input="onAccept"
      :name="name"
    />
    <v-slide-y-reverse-transition leave-absolute>
      <ErrorMessage class="validate-error" name="phone" />
    </v-slide-y-reverse-transition>
  </div>
</template>
