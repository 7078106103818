import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import type { IOffer } from '@/types/offer';
import type { IOption } from '@/types/ui/MySelectProps';
import type { LocationQueryValue } from 'vue-router';
import { useDebounceFn, useLocalStorage } from '@vueuse/core';
import { updateFavorites, updateIsInCart } from '@/services/offer.service';
import { useRoute, useRouter } from 'vue-router';

export type IFavouritesId = number;
export type offersStorage = { offers: IOffer[] };

export const useOfferStore = defineStore('offerStore', () => {
  const offersStorage = ref(
    useLocalStorage('offers', {
      offers: [],
    } as offersStorage)
  );

  const offers = computed(() => offersStorage);

  const searchOfferById = (offerId: number) =>
    offersStorage.value.offers.findIndex((offer) => offer.id === offerId);

  const incrementOffer = (offerId) => {
    const targetOffer = offersStorage.value.offers[searchOfferById(offerId)];
    targetOffer.counter++;
  };

  const decrementOffer = (offerId) => {
    const targetOffer = offersStorage.value.offers[searchOfferById(offerId)];
    if (targetOffer.counter > 1) {
      targetOffer.counter--;
    }
  };

  const toggleFavourites = (offerId: IFavouritesId) => {
    const { execute: executeFavorites } = updateFavorites();

    const targetOffer = offersStorage.value.offers[searchOfferById(offerId)];

    targetOffer.isInFavorite
      ? (targetOffer.isInFavorite = false)
      : (targetOffer.isInFavorite = true);

    executeFavorites(`/offers/${offerId}`, { data: targetOffer }).then(
      (res) => {
        console.log(res);
      }
    );
  };

  const toggleIsInCart = (offerId: IFavouritesId) => {
    const { execute: executeIsInCart } = updateIsInCart();

    const searchOfferById = offersStorage.value.offers.findIndex(
      (offer) => offer.id === offerId
    );
    const targetOffer = offersStorage.value.offers[searchOfferById];

    targetOffer.isInCart
      ? (targetOffer.isInCart = false)
      : (targetOffer.isInCart = true);

    executeIsInCart(`/offers/${offerId}`, { data: targetOffer }).then((res) => {
      console.log(res);
    });
  };

  const getFavouritesOffers = computed(() => {
    return offersStorage.value.offers?.filter(
      (offer: IOffer) => offer.isInFavorite
    );
  });

  const getInCardOffers = computed(() => {
    return offersStorage.value.offers?.filter(
      (offers: IOffer) => offers.isInCart
    );
  });

  const getFavouritesLength = computed(() => getFavouritesOffers.value?.length);

  const getIsInCartLength = computed(() => getInCardOffers.value?.length);

  return {
    offers,
    getFavouritesOffers,
    getFavouritesLength,
    getInCardOffers,
    getIsInCartLength,
    toggleFavourites,
    toggleIsInCart,
    decrementOffer,
    incrementOffer,
  };
});

export const useCatalogStore = defineStore('catalogStore', () => {
  type tagsIds = string | LocationQueryValue[];

  const router = useRouter();
  const route = useRoute();

  const initialCategory = ['all_products'];
  const options: IOption[] = [
    {
      value: 'sort_order',
      text: 'по возрастанию',
    },
    {
      value: 'popular',
      text: 'по популярности',
    },
    {
      value: 'new_first',
      text: 'сначала новые',
    },
    {
      value: 'price-desc',
      text: 'сначала дороже',
    },
    {
      value: 'price-asc',
      text: 'сначале дешевле',
    },
    {
      value: 'alph',
      text: 'по алфавиту',
    },
  ];
  const optionsPerPage: IOption[] = [
    {
      text: '12',
      value: '12',
    },
    {
      text: '18',
      value: '18',
    },
    {
      text: '24',
      value: '24',
    },
  ];

  const fav = ref(0);

  const changeFav = (num: number) => {
    return (fav.value = num);
  };

  const activeCategory = ref<any>(
    route.query.category ? route.query.category : initialCategory
  );

  const activeTags = ref<tagsIds>(
    route.query['tags[]'] ? route.query['tags[]'] : []
  );

  const selectedSort = ref<string | LocationQueryValue[]>(
    route.query.sort ? route.query.sort : options[0].value
  );

  const currentPage = ref<number | LocationQueryValue[]>(
    route.query.page ? Number(route.query.page) : 1
  );

  const perPage = ref<string | LocationQueryValue[]>(
    route.query.perPage ? String(route.query.perpage) : '12'
  );

  const search = ref<string | LocationQueryValue[]>(
    route.query.search ? route.query.search : ''
  );

  const setQueryCategory = (category) => {
    return router.push({
      query: Object.assign({}, route.query, { category }),
    });
  };
  const setQueryTags = (tags) => {
    return router.push({
      query: Object.assign({}, route.query, { 'tags[]': tags }),
    });
  };
  const setQuerySort = (sort) => {
    return router.push({
      query: Object.assign({}, route.query, { sort }),
    });
  };
  const setQueryPage = (page) => {
    return router.push({
      query: Object.assign({}, route.query, { page }),
    });
  };
  const setQueryPerPage = (perpage) => {
    return router.push({
      query: Object.assign({}, route.query, { perpage }),
    });
  };
  const setQuerySearch = (search) => {
    return router.push({
      query: Object.assign({}, route.query, { search }),
    });
  };
  watch(
    () => activeCategory.value,
    (newValue) => {
      if (router.currentRoute.value.name !== 'catalog') {
        console.log('12313');
        return router.push({ name: 'catalog' }).then(() => {
          currentPage.value = 1;
          return setQueryCategory(newValue);
        });
      }
      currentPage.value = 1;
      return setQueryCategory(newValue);
    }
  );

  watch(
    () => activeTags.value,
    (newValue) => {
      console.log(newValue);
      currentPage.value = 1;
      return setQueryTags(newValue);
    }
  );

  watch(
    () => selectedSort.value,
    (newValue) => {
      currentPage.value = 1;
      return setQuerySort(newValue);
    }
  );

  watch(
    () => currentPage.value,
    (newValue) => setQueryPage(newValue)
  );

  watch(
    () => perPage.value,
    (newValue) => {
      currentPage.value = 1;
      return setQueryPerPage(newValue);
    }
  );

  watch(
    () => search.value,
    (newValue) => {
      currentPage.value = 1;
      return setQuerySearch(newValue);
    }
  );

  const initQuery = async () => {
    await setQueryCategory(activeCategory.value).then(() =>
      setQueryTags(activeTags.value)
    );
    setQuerySort(selectedSort.value).then(() =>
      setQueryPage(currentPage.value)
    );
  };

  const changeCategory = (id) => (activeCategory.value = id);

  const searchDebounceFn = useDebounceFn((value: string) => {
    return (search.value = value.toLowerCase());
  }, 500);

  return {
    initQuery,
    activeTags,
    fav,
    changeFav,
    currentPage,
    perPage,
    activeCategory,
    searchDebounceFn,
    search,
    changeCategory,
    selectedSort,
    options,
    optionsPerPage,
  };
});
