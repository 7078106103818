<template>
  <svg
    class="me-3"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.455 2.7066C8.57951 2.39886 8.79309 2.13532 9.06835 1.94975C9.34362 1.76418 9.66803 1.66504 10 1.66504C10.332 1.66504 10.6564 1.76418 10.9317 1.94975C11.2069 2.13532 11.4205 2.39886 11.545 2.7066C12.7773 3.04551 13.8643 3.77957 14.639 4.79605C15.4137 5.81253 15.8333 7.05522 15.8333 8.33327V12.2474L17.36 14.5374C17.4437 14.6629 17.4918 14.8088 17.4991 14.9595C17.5065 15.1102 17.4727 15.26 17.4016 15.3931C17.3304 15.5261 17.2244 15.6373 17.095 15.7148C16.9656 15.7924 16.8175 15.8333 16.6667 15.8333H12.8875C12.7872 16.5275 12.4401 17.1624 11.9098 17.6216C11.3795 18.0807 10.7015 18.3335 10 18.3335C9.29854 18.3335 8.62055 18.0807 8.09025 17.6216C7.55995 17.1624 7.21284 16.5275 7.1125 15.8333H3.33334C3.18247 15.8333 3.03442 15.7924 2.905 15.7148C2.77557 15.6373 2.66962 15.5261 2.59844 15.3931C2.52727 15.26 2.49354 15.1102 2.50086 14.9595C2.50818 14.8088 2.55627 14.6629 2.64 14.5374L4.16667 12.2474V8.33327C4.16667 5.6466 5.98334 3.38327 8.455 2.7066ZM8.82167 15.8333C8.90774 16.0771 9.06732 16.2883 9.27844 16.4377C9.48956 16.5871 9.7418 16.6673 10.0004 16.6673C10.259 16.6673 10.5113 16.5871 10.7224 16.4377C10.9335 16.2883 11.0931 16.0771 11.1792 15.8333H8.82084H8.82167ZM10 4.1666C8.89493 4.1666 7.83513 4.60559 7.05372 5.38699C6.27232 6.16839 5.83334 7.2282 5.83334 8.33327V12.4999C5.83337 12.6645 5.78466 12.8255 5.69334 12.9624L4.89084 14.1666H15.1083L14.3058 12.9624C14.2148 12.8254 14.1664 12.6645 14.1667 12.4999V8.33327C14.1667 7.2282 13.7277 6.16839 12.9463 5.38699C12.1649 4.60559 11.1051 4.1666 10 4.1666Z"
      fill="#C06700"
    ></path>
  </svg>
</template>
