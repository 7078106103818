import { ref, watch } from 'vue';
import { defineStore } from 'pinia';
import { useRoute } from 'vue-router';
import { toggleBodyScroll } from '@/helper/DOM';

export const useHeaderStore = defineStore('headerStore', () => {
  const menuVisibility = ref(false);
  const route = useRoute();

  const changeVisibility = () => (menuVisibility.value = !menuVisibility.value);

  watch(
    () => route.name,
    () => (menuVisibility.value ? changeVisibility() : null)
  );

  watch(
    () => route.query,
    () => (menuVisibility.value ? changeVisibility() : null)
  );

  watch(
    () => menuVisibility.value,
    () => toggleBodyScroll(menuVisibility.value)
  );

  return {
    menuVisibility,
    changeVisibility,
  };
});
