import instance from '@/utils/axios';
import type {
  IGetLimitedNewsResponse,
  IGetLimitedOffersResponse,
} from '@/services/types/responseTypes';

export const MainService = {
  async getLimitedNews() {
    return instance.get<IGetLimitedNewsResponse>('/main/get-news');
  },
  async getLimitedOffers() {
    return instance.get<IGetLimitedOffersResponse>('/main/get-offers');
  },
};
