import { useQuery } from '@tanstack/vue-query';
import { CatalogService } from '@/services/catalog.service';
import { useCatalogStore } from '@/stores/catalogStore';
import { storeToRefs } from 'pinia';

export const useCatalog = () => {
  const store = useCatalogStore();

  const {
    activeCategory,
    activeTags,
    selectedSort,
    currentPage,
    perPage,
    search,
  } = storeToRefs(store);

  const {
    isLoading,
    isFetching,
    isSuccess,
    data: catalog,
  } = useQuery({
    queryKey: [
      'catalog',
      activeCategory,
      activeTags,
      selectedSort,
      perPage,
      currentPage,
      search,
    ],
    queryFn: () =>
      CatalogService.getCatalog(
        activeCategory.value[0],
        activeTags.value,
        selectedSort.value,
        perPage.value,
        currentPage.value,
        search.value
      ),
    staleTime: 10000,
    keepPreviousData: true,
    select: (data) => data.data.data,
  });

  return { isLoading, isSuccess, catalog, isFetching };
};
