<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useModalStore } from '@/stores/modalStore';
  import AuthModal from '@/components/modals/authModal/AuthModal.vue';

  const store = useModalStore();

  const { authIsOpen } = storeToRefs(store);

  const { toggleAuthIsOpen } = store;
</script>

<template>
  <button
    @click="toggleAuthIsOpen"
    class="btn-icon btn-icon-secondary btn-icon-lg user text-wrap"
    type="button"
    data-bs-toggle="modal"
    data-bs-target="#loginPasswordModal"
  >
    <span class="text">Войти</span>
  </button>
  <AuthModal :open="authIsOpen" @closeModal="toggleAuthIsOpen" />
</template>
