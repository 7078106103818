import instance from '@/utils/axios';
import type {
  authForm,
  regForm,
  registerPasswordForm,
  resetTokenForm,
  resetPasswordForm,
} from '@/services/types/dataTypes';
import type {
  IAuthUserResponse,
  IRegisterPasswordResponse,
  IRegisterUserResponse,
  IResetPasswordByToken,
} from '@/services/types/responseTypes';
import type { IGetAccountResponse } from '@/services/types/IGetAccountResponse';

export const UserService = {
  async auth(formData: authForm) {
    return instance.post<IAuthUserResponse>('/auth-by-password', formData);
  },
  async register(formData: regForm) {
    return instance.post<IRegisterUserResponse>('/send-code', formData);
  },
  async authByToken(formData: resetTokenForm) {
    return instance.post<IResetPasswordByToken>('/auth-by-token', formData);
  },
  async registerPassword(formData: registerPasswordForm) {
    return instance.post<IRegisterPasswordResponse>(
      '/register-password',
      formData
    );
  },
  async resetPassword(formData: resetPasswordForm) {
    return instance.post('/reset-password', formData);
  },
  async resetPasswordByToken(formData: resetTokenForm) {
    return instance.post<IResetPasswordByToken>(
      '/reset-password-by-token',
      formData
    );
  },
  async logout() {
    return instance.post('/logout');
  },
  async getAccount() {
    return instance.get<IGetAccountResponse>('/account');
  },
};
