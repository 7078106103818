<script setup lang="ts">
  import { Field, ErrorMessage } from 'vee-validate';
  import IconEye from '@/components/icons/IconEye.vue';
  import { computed, ref } from 'vue';

  interface Props {
    label?: string;
    id: string;
    name: string;
    type?: string;
    as?: string;
    error?: string;
    value?: string;
    placeholder?: string;
  }

  const props = withDefaults(defineProps<Props>(), {
    type: 'text',
  });

  const reactiveType = ref(props.type);

  const currentValue = computed(() => (props.value ? props.value : ''));

  const changeTypeField = () => {
    if (reactiveType.value === 'password') {
      console.log(reactiveType.value);

      return (reactiveType.value = 'text');
    }
    return (reactiveType.value = 'password');
  };
</script>

<template>
  <div class="form-wrapper">
    <label class="form-label" :for="id">{{ label }}</label>
    <Field
      class="form-control"
      autocomplete="new-password"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      :placeholder="placeholder"
      v-model="currentValue"
      :as="as"
      :id="id"
      :type="reactiveType"
      :name="name"
      validateOnInput
    />
    <v-icon
      v-if="type === 'password'"
      class="icon-eye"
      :class="[{ active: reactiveType === 'text' }]"
      :icon="IconEye"
      @click="changeTypeField"
    />
    <v-slide-y-reverse-transition group leave-absolute>
      <span v-if="error" class="validate-error">{{ error }}</span>
      <ErrorMessage v-else class="validate-error" :name="name" />
    </v-slide-y-reverse-transition>
  </div>
</template>

<style lang="scss">
  .icon-eye {
    position: absolute !important;
    top: calc(50%);
    transform: translateY(17%);
    right: 20px;
    svg {
      transition: all 0.15s ease-in-out;
      color: #ded8d1 !important;
    }

    &.active {
      svg {
        color: #95c11f !important;
      }
    }
  }

  .validate-error {
    z-index: -1;
    position: absolute;
    top: 103%;
    width: 100%;
    font-size: 0.875rem;
    color: #ea1c00;
  }
</style>
