<script setup lang="ts">
  import { useCatalogStore } from '@/stores/catalogStore';
  import { storeToRefs } from 'pinia';

  const store = useCatalogStore();

  const { fav } = storeToRefs(store);
</script>

<template>
  <router-link
    to="/favourites"
    class="btn-icon btn-icon-secondary btn-icon-lg favourites favouritesLinkJs text-wrap"
  >
    <span class="text">Избранное</span>
    <span v-if="fav > 0" class="count-text">{{ fav }}</span>
  </router-link>
</template>
