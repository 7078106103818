import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useModalStore = defineStore('modalStore', () => {
  const authIsOpen = ref<boolean>(false);
  const reviewIsOpen = ref<boolean>(false);
  const registerIsOpen = ref<boolean>(false);
  const resetIsOpen = ref<boolean>(false);
  const enterCodeIsOpen = ref<boolean>(false);
  const pickPasswordIsOpen = ref<boolean>(false);
  const successMailIsOpen = ref<boolean>(false);

  const toggleAuthIsOpen = () => {
    if (registerIsOpen.value) {
      toggleRegIsOpen();
      return setTimeout(() => (authIsOpen.value = !authIsOpen.value), 300);
    }
    if (resetIsOpen.value) {
      toggleResetIsOpen();
      return setTimeout(() => (authIsOpen.value = !authIsOpen.value), 300);
    }

    return (authIsOpen.value = !authIsOpen.value);
  };
  const toggleReviewIsOpen = () => (reviewIsOpen.value = !reviewIsOpen.value);
  const toggleRegIsOpen = () => {
    if (authIsOpen.value) {
      toggleAuthIsOpen();
      return setTimeout(
        () => (registerIsOpen.value = !registerIsOpen.value),
        300
      );
    }
    if (enterCodeIsOpen.value) {
      toggleEnterCodeIsOpen();
      return setTimeout(
        () => (registerIsOpen.value = !registerIsOpen.value),
        300
      );
    }
    return (registerIsOpen.value = !registerIsOpen.value);
  };

  const toggleResetIsOpen = () => {
    if (authIsOpen.value) {
      toggleAuthIsOpen();
      return setTimeout(() => (resetIsOpen.value = !resetIsOpen.value), 300);
    }
    return (resetIsOpen.value = !resetIsOpen.value);
  };

  const toggleEnterCodeIsOpen = () => {
    if (registerIsOpen.value) {
      toggleRegIsOpen();
      return setTimeout(
        () => (enterCodeIsOpen.value = !enterCodeIsOpen.value),
        300
      );
    }
    return (enterCodeIsOpen.value = !enterCodeIsOpen.value);
  };
  const togglePickPassword = () => {
    return (pickPasswordIsOpen.value = !pickPasswordIsOpen.value);
  };
  const toggleSuccessIsOpen = () => {
    return (successMailIsOpen.value = !successMailIsOpen.value);
  };

  return {
    authIsOpen,
    reviewIsOpen,
    registerIsOpen,
    resetIsOpen,
    enterCodeIsOpen,
    pickPasswordIsOpen,
    successMailIsOpen,
    toggleAuthIsOpen,
    toggleReviewIsOpen,
    toggleRegIsOpen,
    toggleResetIsOpen,
    toggleEnterCodeIsOpen,
    togglePickPassword,
    toggleSuccessIsOpen,
  };
});
