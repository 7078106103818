<script setup lang="ts">
  import { Form } from 'vee-validate';
  import { validateFormAuth } from '@/helper/validate/schemas';
  import { ref } from 'vue';
  import { useModalStore } from '@/stores/modalStore';
  import { useAuth } from '@/hooks/queries/user/useAuth';
  import ModalRoot from '../ModalRoot.vue';
  import RegBtn from '../regModal/component/RegBtn.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import MyInput from '@/components/ui/form/MyInput.vue';
  import type { authForm } from '@/services/types/dataTypes';

  interface Props {
    open: boolean;
  }

  defineProps<Props>();

  const formData = ref<authForm | null>(null);

  const { isLoading, refetch } = useAuth(formData);

  const modalStore = useModalStore();

  const { toggleResetIsOpen } = modalStore;

  const onSubmitAuthForm = async (data) => {
    const regEmail = /^[\w-.]+@[\w-]+\.[a-z]{2,4}$/i;
    if (regEmail.test(data.credentials)) {
      formData.value = {
        type: 'email',
        email: data.credentials,
        phone: data.credentials,
        password: data.password,
      };
    } else {
      formData.value = {
        type: 'phone',
        email: data.credentials,
        phone: data.credentials,
        password: data.password,
      };
    }
    await refetch();
  };
</script>

<template>
  <ModalRoot title="Войти по паролю" :open="open">
    <div class="modal-body">
      <Form @submit="onSubmitAuthForm" :validation-schema="validateFormAuth">
        <div class="form-wrapper">
          <label class="form-label" for="emailOrPhone2"
            >E-mail или телефон</label
          >
          <div class="g-tooltip ms-auto">
            <div class="g-tooltip__ic"></div>
            <div class="g-tooltip__dropdown">
              Вводите телефон в формате <br />
              7 900 00 00 000
            </div>
          </div>
          <MyInput id="emailOrPhone2" name="credentials" />
        </div>
        <MyInput
          id="loginPassword"
          type="password"
          name="password"
          label="Пароль"
        />
        <div class="modal-footer justify-content-start">
          <v-row class="mb-4" no-gutters>
            <v-col style="height: 60px" cols="12" md="6">
              <my-v-btn
                type="submit"
                color="primary"
                width="100%"
                :append-icon="IconArrow"
                :loading="isLoading"
              >
                Авторизация
              </my-v-btn>
            </v-col>
            <v-col
              class="ps-lg-3 mt-4 mt-md-0"
              style="height: 60px"
              cols="12"
              md="6"
            >
              <RegBtn />
            </v-col>
          </v-row>
          <p class="text-gray text-center w-100 m-0">
            <span class="w-100 d-block">Не получилось войти?</span>
            <button
              type="button"
              class="link-primary bold mb-0"
              data-bs-toggle="modal"
              data-bs-target="#passwordRecoveryModal"
              @click="toggleResetIsOpen"
            >
              Сбросить пароль
            </button>
          </p>
        </div>
      </Form>
    </div>
  </ModalRoot>
</template>
