<script setup lang="ts">
  import ModalRoot from '@/components/modals/ModalRoot.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import { useModalStore } from '@/stores/modalStore';

  interface Props {
    open: boolean;
  }

  defineProps<Props>();

  const modalStore = useModalStore();

  const { toggleSuccessIsOpen } = modalStore;

  const successTitle = 'Сообщение успешно отправлено на ваш email';
</script>

<template>
  <ModalRoot :title="successTitle" :open="open" :is-first-step="false">
    <div class="modal-body">
      <p class="bold text-center" data-success-reset-modal-text="" />
    </div>
    <div class="modal-footer justify-content-start">
      <div class="d-flex align-items-center justify-content-center w-100">
        <my-v-btn @click="toggleSuccessIsOpen"> Понятно </my-v-btn>
      </div>
    </div>
  </ModalRoot>
</template>
