import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.min.css';
import { aliases, mdi } from 'vuetify/iconsets/mdi';

import { createVuetify } from 'vuetify';

export const vuetify = createVuetify({
  defaults: {
    global: {
      ripple: false,
    },
  },
  theme: {
    themes: {
      default: {
        colors: {
          white: '#fff',
          black: '#454443',
          primary: '#c06700',
          secondary: '#ded8d1',
          grayLight: '#f8f8f8',
        },
      },
    },
  },
  display: {
    thresholds: {
      xs: 0,
      sm: 340,
      md: 540,
      lg: 992,
      xl: 1200,
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
});
