<script setup lang="ts">
  import ModalRoot from '../ModalRoot.vue';
  import { useModalStore } from '@/stores/modalStore';
  import { validateFormRegister } from '@/helper/validate/schemas';
  import { Form } from 'vee-validate';
  import { ref } from 'vue';
  import MyInput from '@/components/ui/form/MyInput.vue';
  import IconArrow from '@/components/icons/arrows/IconArrow.vue';
  import MyVBtn from '@/components/ui/buttons/MyVBtn.vue';
  import IconKey from '@/components/icons/IconKey.vue';
  import type { regForm } from '@/services/types/dataTypes';
  import { useRegister } from '@/hooks/queries/user/useRegister';

  interface Props {
    open: boolean;
  }

  defineProps<Props>();

  const store = useModalStore();

  const { toggleAuthIsOpen } = store;

  const formData = ref<regForm | null>(null);

  const { isLoading, refetch } = useRegister(formData);

  const onSubmitRegisterUser = async (data) => {
    const regEmail = /^[\w-.]+@[\w-]+\.[a-z]{2,4}$/i;
    if (regEmail.test(data.credentials)) {
      formData.value = {
        type: 'email',
        email: data.credentials,
        phone: data.credentials,
        name: data.name,
        surname: data.surname,
      };
    } else {
      formData.value = {
        type: 'phone',
        email: data.credentials,
        phone: data.credentials,
        name: data.name,
        surname: data.surname,
      };
    }

    await refetch();
  };
</script>

<template>
  <ModalRoot title="Регистрация" :open="open" :is-first-step="false">
    <div class="modal-body">
      <Form
        @submit="onSubmitRegisterUser"
        :validation-schema="validateFormRegister"
      >
        <MyInput
          id="login_credentials"
          name="credentials"
          label="E-mail или телефон"
        />
        <MyInput id="surnameModal" name="surname" label="Фамилия" />
        <MyInput id="nameModal" name="name" label="Имя" />
        <div class="modal-footer justify-content-start pt-2">
          <v-row class="mb-4" no-gutters>
            <v-col style="height: 60px" cols="12" md="6">
              <my-v-btn
                type="submit"
                color="primary"
                width="100%"
                :append-icon="IconArrow"
                :loading="isLoading"
              >
                Получить код
              </my-v-btn>
            </v-col>
            <v-col
              class="ps-lg-3 mt-4 mt-md-0"
              style="height: 60px"
              cols="12"
              md="6"
            >
              <my-v-btn
                width="100%"
                color="secondary"
                variant="outlined"
                :append-icon="IconKey"
                @click="toggleAuthIsOpen"
              >
                Войти
              </my-v-btn>
            </v-col>
          </v-row>
          <p class="text-gray text-center m-0">
            Заполняя форму регистации Вы соглашаетесь с нашей <br />
            <a href="#" class="link-primary bold mb-0"
              >политикой конфедициальности</a
            >
          </p>
        </div>
      </Form>
    </div>
  </ModalRoot>
</template>
